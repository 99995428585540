import { ALL_PLANETS_ASSETS } from "./assets/images";

export type Config = {
  allowDeselect: boolean;
  allowClones: boolean;
  allowSelectOnPause: boolean;
  isRandomPosition: boolean;
  isRandomSize: boolean;
  warpTimeSec: number;
  rotationK: number;
  screen: number;
  screensTotal: number,
  isPaused: boolean;
  buttonsOn: boolean;
  soundOn: boolean;
  isRandomSpeed: boolean;
  isOrderRequired: boolean;
  nearestPlanetRange: number;
  isRemoveAllForOrderedMode: boolean;
  totalPlanets: number;
  splashSeconds: number;

  processSmallPlanetTap?: (i: number, isRandomSpeed: boolean) => void;
  resetWarpTimer?: (time: number) => void;
};

const DEFAULT_CONFIG: Config = {
  allowDeselect: false,
  allowClones: false,
  allowSelectOnPause: false,
  isRandomPosition: true,
  isRandomSize: true,
  warpTimeSec: 1,
  rotationK: 9,
  screen: 0,
  screensTotal: 2,
  isPaused: false,
  buttonsOn: true,
  soundOn: true,
  isRandomSpeed: false,
  isOrderRequired: false,
  nearestPlanetRange: 2,
  totalPlanets: 9,
  isRemoveAllForOrderedMode: true,
  splashSeconds: 8,

  processSmallPlanetTap: undefined
};

export const CONTAINER_SIZES = { x: 10, y: 10, width: 50, padding: 5, margin: 15 };
export const BUTTON_SIZES = { x: 10, y: 10, width: 50, padding: 5, margin: 15 };

function getQueryParamAsNumber(param, defaultValue = 0) {
  const params = new URLSearchParams(window.location.search);
  const value = params.get(param);

  const numberValue = parseInt(value, 10);

  return isNaN(numberValue) ? defaultValue : numberValue;
}

function toBoolean(value: string | null): boolean {
  if (value === '0') return false;
  if (value === '1') return true;
  if (value === "") return true;
  return null;
}

export function parseAndMergeParams(): Config {
  const params = new URLSearchParams(window.location.search);

  const config: Config = {
    allowDeselect: toBoolean(params.get('ds')) ?? DEFAULT_CONFIG.allowDeselect,
    allowClones: toBoolean(params.get('cl')) ?? DEFAULT_CONFIG.allowClones,
    allowSelectOnPause: toBoolean(params.get('ps')) ?? DEFAULT_CONFIG.allowSelectOnPause,
    isRandomPosition: toBoolean(params.get('rp')) ?? DEFAULT_CONFIG.isRandomPosition,
    isRandomSize: toBoolean(params.get('rs')) ?? DEFAULT_CONFIG.isRandomSize,
    warpTimeSec: getQueryParamAsNumber('wt', DEFAULT_CONFIG.warpTimeSec),
    rotationK: getQueryParamAsNumber('rk', DEFAULT_CONFIG.rotationK),
    screen: getQueryParamAsNumber('sc', DEFAULT_CONFIG.screen),
    isPaused: /*toBoolean(params.get('ip')) ??*/ DEFAULT_CONFIG.isPaused,
    screensTotal: DEFAULT_CONFIG.screensTotal,
    buttonsOn: toBoolean(params.get('bu')) ?? DEFAULT_CONFIG.buttonsOn,
    soundOn: toBoolean(params.get('so')) ?? DEFAULT_CONFIG.soundOn,
    isRandomSpeed: toBoolean(params.get('rsp')) ?? DEFAULT_CONFIG.isRandomSpeed,
    isOrderRequired: toBoolean(params.get('io')) ?? DEFAULT_CONFIG.isOrderRequired,
    nearestPlanetRange: getQueryParamAsNumber('np', DEFAULT_CONFIG.nearestPlanetRange),
    totalPlanets: ALL_PLANETS_ASSETS.length,
    isRemoveAllForOrderedMode: toBoolean(params.get('ra')) ?? DEFAULT_CONFIG.isRemoveAllForOrderedMode,
    splashSeconds: getQueryParamAsNumber('ss', DEFAULT_CONFIG.splashSeconds),
  };

  return config;
}