import { createOrbit, hideOrbit, showOrbit } from "./orbit";
import { hidePlanets, showPlanets } from "./planets";
import { Config } from "./settings"

export const SCREENS_ALLOWED_PAUSE = [0];

export function nextScreen(app, SETTINGS: Config) {
  // console.log("Next screen")
  SETTINGS.screen = (SETTINGS.screen + 1) % SETTINGS.screensTotal;
  // console.log("new screen: ", SETTINGS.screen)
  if (SETTINGS.screen === 1) {
    SETTINGS.isPaused = true;
    hidePlanets();
    showOrbit();
  } else {
    SETTINGS.isPaused = false;
    showPlanets();
    hideOrbit();
  }
}

export function prevScreen(app, SETTINGS: Config) {
  // console.log("Prev screen")
  SETTINGS.screen = (SETTINGS.screen - 1) % SETTINGS.screensTotal;
  // console.log("new screen: ", SETTINGS.screen)
  if (SETTINGS.screen === 1) {
    SETTINGS.isPaused = true;
    hidePlanets();
    showOrbit();
  } else {
    SETTINGS.isPaused = false;
    showPlanets();
    hideOrbit();
  }
}