import { Application, Container, Graphics, Text, TextStyle } from "pixi.js";
import { setSound } from "./sound";
import { Config } from "./settings";

export function addButtons(app, SETTINGS: Config, nextScreenFunc, prevScreenFunc) {
  const buttonsContainer = new Container();
  buttonsContainer.position.set(0, app.screen.height - 25);
  buttonsContainer.eventMode = 'static';
  buttonsContainer.cursor = 'pointer';
  buttonsContainer.addListener('pointerdown', () => {
    // startPlay();
    // console.log("POINTER DOWN")
  });

  const buttonView = new Graphics().rect(0, 0, app.screen.width, 25).stroke({ color: 'transparent', width: 10 }).fill({ color: 'transparent' });
  buttonsContainer.addChild(buttonView)
  app.stage.addChild(buttonsContainer);

  const style = new TextStyle({
    fontFamily: 'Arial',
    fontSize: 40,
    stroke: { color: 0x4a1850, width: 5 }
  });

  const nextText = new Text({ text: '🌓', style }) //🤙👉👉🏼
  nextText.anchor = 0.5;
  nextText.cursor = 'pointer';
  nextText.eventMode = 'static';
  nextText.position.set(Math.min(buttonsContainer.width, app.screen.width) - 25, 0)
  nextText.addListener('pointertap', () => nextScreenFunc(app, SETTINGS));

  const soundText = new Text({ text: '🎵', style }) //🤙👈🏼
  soundText.anchor = 0.5
  soundText.eventMode = 'static';
  soundText.cursor = 'pointer';
  soundText.position.set(75, 0)
  soundText.addListener('pointertap', () => {
    // console.log(SETTINGS.soundOn)
    SETTINGS.soundOn = !SETTINGS.soundOn
    setSound(SETTINGS.soundOn)
  });

  const prevText = new Text({ text: '🌗', style }) //🤙👈🏼
  prevText.anchor = 0.5
  prevText.eventMode = 'static';
  prevText.cursor = 'pointer';
  prevText.position.set(25, 0)
  prevText.addListener('pointertap', () => prevScreenFunc(app, SETTINGS));

  const modes = ['🧑🏼‍🚀', '🧬', '👽']
  let modeIndex = 0;
  const modeText = new Text({ text: modes[modeIndex], style }) //🤙👈🏼
  modeText.anchor = 0.5
  modeText.eventMode = 'static';
  modeText.cursor = 'pointer';
  modeText.position.set(Math.min(buttonsContainer.width, app.screen.width) - 75, 0)
  // let isAlien = false;
  // let isClone = false;
  modeText.addListener('pointertap', () => {
    modeIndex = (modeIndex + 1) % modes.length
    // isAlien = modeIndex === 2;
    modeText.text = modes[modeIndex]; //isAlien ? '👽' : '🧑🏼‍🚀';
    SETTINGS.isOrderRequired = modeIndex === 2;
    SETTINGS.allowClones = modeIndex === 1;
  });

  buttonsContainer.addChild(prevText);
  buttonsContainer.addChild(soundText);
  buttonsContainer.addChild(modeText);
  buttonsContainer.addChild(nextText);

  const slider = createSlider(app, SETTINGS);
  buttonsContainer.addChild(slider)
  return buttonsContainer;
}

function createSlider(app: Application, SETTINGS: Config) {
  const sliderWidth = 140;
  const stageWidth = app.screen.width;
  // const stageHeight = app.screen.height;
  const slider = new Graphics().rect(0, 0, sliderWidth, 4).fill({ color: 0x272d37 });
  slider.x = (stageWidth - sliderWidth) / 2;
  // slider.y = stageHeight - 0.75;
  const handle = new Graphics().circle(0, 0, 8).fill({ color: 0xffffff });
  handle.y = slider.height / 2;
  handle.x = 14;// 0
  handle.eventMode = 'static';
  handle.cursor = 'pointer';
  handle.on('pointerdown', onDragStart).on('pointerup', onDragEnd).on('pointerupoutside', onDragEnd);
  slider.addChild(handle);

  function onDragStart() {
    app.stage.eventMode = 'static';
    app.stage.addEventListener('pointermove', onDrag);
  }

  function onDragEnd(e) {
    app.stage.eventMode = 'auto';
    app.stage.removeEventListener('pointermove', onDrag);
  }

  function onDrag(e) {
    const halfHandleWidth = handle.width / 2;
    handle.x = Math.max(halfHandleWidth, Math.min(slider.toLocal(e.global).x, sliderWidth - halfHandleWidth));
    const t = 8 * (handle.x / sliderWidth - 0.5);
    SETTINGS.warpTimeSec = (3.2 + 1 + t)
    // console.log(t, SETTINGS.warpTimeSec, handle.x)
    SETTINGS.resetWarpTimer(SETTINGS.warpTimeSec);
  }

  return slider;
}