import Jupiter from "./jupiter.png";
import Mars from "./mars.png";
import Pluto from "./pluto.png";
import Asteroid from "./asteroid.png";
import Ceres from "./ceres.png";
import Earth from "./earth.png";
import Eris from "./eris.png";
import Makemake from "./makemake.png";
import Mercury from "./mercury.png";
import Moon from "./moon.png";
import Neptune from "./neptune.png";
import Sun from "./sun.png";
import Uranus from "./uranus.png";
import Venus from "./venus.png";
import Saturn from "./saturn.png";

export { Jupiter, Mars, Pluto, Asteroid, Ceres, Earth, Eris, Makemake, Mercury, Moon, Saturn, Neptune, Sun, Uranus, Venus };
export const ALL_PLANETS_ASSETS = [Mercury, Venus, Earth, Mars, Jupiter, Saturn, Uranus, Neptune, Pluto];
export const ALL_STARS_ASSETS = [Sun];
export const ALL_OBJEcTS_ASSETS = [Asteroid, Moon, Eris, Makemake, Ceres];