import { Howl } from 'howler';
import bgMusic from './assets/sounds/bg_music.mp3';

let howl = null;
export function setupSounds() {
  // Background music
  document.addEventListener('click', async () => {
    howl = new Howl({ src: [bgMusic], volume: 0.1, autoplay: true, loop: true });
  }, { once: true });
}

export function setSound(soundOn: boolean) {
  console.log({ howl, soundOn })
  if (soundOn) {
    howl.play()
  } else {
    howl.pause();
  }
  // howl.volume = soundOn ? 1 : 0;
}