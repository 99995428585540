import { Application, Text, TextStyle } from "pixi.js";
import { Config } from "./settings";

export function addSplash(app: Application, SETTINGS: Config) {
  const style = new TextStyle({
    fontFamily: 'Orbitron, sans-serif',
    fontSize: 36,
    stroke: { color: 0xFFFF66, width: 4, join: 'round' },
    wordWrap: true,
    wordWrapWidth: Math.min(500, app.screen.width),
  });

  const nextText = new Text({
    text:
      `🚀 Congratulations!\n\nYou learned a lot about exoplanets.\n\nIt's time now to return home, to our Solar system! 🌍\n\nClick any planet to play.`
    , style
  })
  nextText.anchor = 0.5;
  nextText.position.set(app.screen.width / 2, app.screen.height / 2);
  nextText.visible = true;

  app.stage.addChild(nextText);

  SETTINGS.isPaused = true;
  const timeout = SETTINGS.splashSeconds !== undefined && SETTINGS.splashSeconds !== null ? SETTINGS.splashSeconds * 1000 : 8000;
  setTimeout(() => {
    app.stage.removeChild(nextText)
    SETTINGS.isPaused = false;
  }, timeout);
}