import { Application, Container, Sprite } from "pixi.js";
import { getOldIndex, loadPlanetsTextures, loadStarsTextures } from "./loader";
import { Config, CONTAINER_SIZES } from "./settings";
import { resize } from "./planets";

interface State {
  planet: Sprite;
  selectedPlanets: number[],
  orbitContainer: Container,
  scaleFactor: number,
  maxRadius: number
}
const STATE: State = {
  planet: undefined,
  selectedPlanets: [],
  orbitContainer: undefined,
  scaleFactor: undefined,
  maxRadius: undefined
}
const setScaleFactor = (app) => {
  STATE.scaleFactor = Math.min(app.screen.width, app.screen.height) / 800;
  STATE.maxRadius = Math.min(app.screen.width, app.screen.height) / 2 - 50;
}
export async function createOrbit(app: Application, settings: Config) {
  if (STATE.planet) return;

  const planetsTextures = await loadStarsTextures()
  const planet = new Sprite(planetsTextures[0]);
  planet.anchor.set(0.5);
  setScaleFactor(app)
  planet.position.set(app.screen.width / 2, app.screen.height / 2);
  planet.visible = false;
  planet.scale.set(0.3 * Math.min(STATE.scaleFactor, 1))
  planet.eventMode = 'static';
  planet.cursor = 'pointer';
  STATE.planet = planet;
  app.stage.addChild(STATE.planet);

  setupOrbitContainer(app);

  settings.processSmallPlanetTap = selectPlanet;
}

export function showOrbit() {
  // console.log(STATE.planet)
  if (STATE.planet) {
    STATE.planet.visible = true;
    STATE.orbitContainer.visible = true;
  }
}

export function setupOrbitContainer(app) {
  const orbitContainer = new Container();
  orbitContainer.visible = false;
  orbitContainer.position.set(CONTAINER_SIZES.x, CONTAINER_SIZES.y);
  app.stage.addChild(orbitContainer);
  window.addEventListener('resize', () => {
    resize(app, orbitContainer);
    setScaleFactor(app);
  });
  STATE.orbitContainer = orbitContainer;
  STATE.scaleFactor = Math.min(app.screen.width, app.screen.height) / 600;

  return orbitContainer;
}

const orbitalSpeeds = [
  0.04787, // Mercury (fastest)
  0.03502, // Venus
  0.02978, // Earth
  0.02407, // Mars
  0.01307, // Jupiter
  0.00968, // Saturn
  0.00681, // Uranus
  0.00543  // Neptune (slowest)
];

export async function selectPlanet(i, isRandomSpeed) {
  // console.log("SELECT PLANET ", i)
  if (STATE.selectedPlanets.includes(i)) return;

  STATE.selectedPlanets.push(i);

  const planetsTextures = await loadPlanetsTextures()

  const planetSmall = new Sprite(planetsTextures[i % planetsTextures.length]);
  const originalSize = planetSmall.getSize();
  const aspectRatio = originalSize.height / originalSize.width;
  // console.log(STATE.scaleFactor)
  const scale = Math.min(STATE.scaleFactor, 1);
  planetSmall.setSize(CONTAINER_SIZES.width * scale, scale * CONTAINER_SIZES.width * aspectRatio);
  planetSmall.anchor.set(0.5);
  planetSmall.angle = Math.random() * Math.PI * 2;
  // planetSmall.position.set((STATE.selectedPlanets.length - 1) * (CONTAINER_SIZES.width + CONTAINER_SIZES.padding) + CONTAINER_SIZES.margin, CONTAINER_SIZES.margin);
  // planetSmall.anchor.set(0.5);
  // planetSmall.orbitRadius = 150 + i * 70;
  const oldIndex = getOldIndex(i);
  planetSmall.orbitRadius = (100 + oldIndex * 50) * STATE.scaleFactor

  // const numPlanets = orbitalSpeeds.length;
  // const radiusStep = STATE.maxRadius / numPlanets;
  // planetSmall.orbitRadius = (i + 1) * radiusStep;

  planetSmall.speed = isRandomSpeed ? 0.004 + Math.random() * 0.004 : orbitalSpeeds[oldIndex] / 5;
  STATE.orbitContainer.addChild(planetSmall);
}

export function hideOrbit() {
  if (STATE.planet) {
    STATE.planet.visible = false;
    STATE.orbitContainer.visible = false;
  }
}
export function processOrbitTick(time, SETTINGS: Config) {
  if (STATE.planet && STATE.orbitContainer && SETTINGS.screen === 1) {
    STATE.planet.rotation += (SETTINGS.rotationK / 10000) * time.deltaTime;
    STATE.orbitContainer.children.forEach(c => {
      // c.rotation += (SETTINGS.rotationK / 1000) * time.deltaTime;
      c.angle += c.speed * time.deltaTime;

      // if (c.angle > Math.PI * 2) {
      // c.angle -= Math.PI * 2;
      // }

      // console.log(c.angle, c.speed, c.orbitRadius, c.x, c.y)
      c.x = STATE.planet.x + Math.cos(c.angle) * c.orbitRadius;
      c.y = STATE.planet.y + Math.sin(c.angle) * c.orbitRadius;
    });
  }
  // /*!isPaused &&*/ STATE.tween && STATE.tween.update();
}