import { Application } from 'pixi.js';
import { parseAndMergeParams } from './settings';
import { processStarsTick, setupStars, toggleWarpSpeed } from './stars';
import { setupGestures } from './gestures';
import { setupSounds } from './sound';
import { addButtons } from './buttons';
import { animatePlanetOnWarpTick, processPlanetsTick, setupPlanets, setupPlanetsContainer } from './planets';
import { loadPlanetsTextures } from './loader';
import { nextScreen, prevScreen } from './screens';
import { createOrbit, processOrbitTick } from './orbit';
import { addSplash } from './splash';

interface DestroyState {
  hammer: HammerManager;
  warpTimer: NodeJS.Timer;
  cleanScreenWarp: (app: any) => void;
}
export const SCREEN_STATE: DestroyState = {
  hammer: undefined,
  warpTimer: undefined,
  cleanScreenWarp: (app) => {
    SCREEN_STATE.hammer.destroy();
    clearTimeout(SCREEN_STATE.warpTimer);
    app.stage.removeChildren();
  }
};

(async () => {
  const SETTINGS = parseAndMergeParams();
  setupSounds();

  // Create a new application
  const app = new Application();
  await app.init({ resizeTo: window });
  app.stage.eventMode = 'static';
  app.stage.hitArea = app.screen;
  document.body.appendChild(app.canvas);

  const setupScreenWarp = async () => {
    const planetsTextures = await loadPlanetsTextures();
    const buttonsContainer = SETTINGS.buttonsOn ? addButtons(app, SETTINGS, nextScreen, prevScreen) : undefined;
    addSplash(app, SETTINGS);
    const planetsContainer = setupPlanetsContainer(app);
    SCREEN_STATE.hammer = setupGestures(app, SETTINGS, planetsContainer, buttonsContainer);
    await setupStars(app);
    setupPlanets(app, SETTINGS, planetsTextures);

    // Change flight speed every 5 seconds
    const warpTime = SETTINGS.warpTimeSec * 1000;
    SCREEN_STATE.warpTimer = setInterval(() => {
      if (SETTINGS.isPaused) return;
      const warpSpeed = toggleWarpSpeed();
      animatePlanetOnWarpTick(app, SETTINGS, warpSpeed);
    }, warpTime);

    SETTINGS.resetWarpTimer = (time: number) => {
      clearTimeout(SCREEN_STATE.warpTimer);
      const warpTime = SETTINGS.warpTimeSec * 1000; // time * 1000;
      SCREEN_STATE.warpTimer = setInterval(() => {
        if (SETTINGS.isPaused) return;
        const warpSpeed = toggleWarpSpeed();
        animatePlanetOnWarpTick(app, SETTINGS, warpSpeed);
      }, warpTime);
    }

    // Listen for animate update
    const processTicker = (time) => {
      processPlanetsTick(time, SETTINGS);
      processStarsTick(app, time);
      processOrbitTick(time, SETTINGS); // move to separate screen init?
    };

    app.ticker.add(processTicker);
  }

  await setupScreenWarp();
  await createOrbit(app, SETTINGS);
})();
