import { Assets } from "pixi.js";
import { ALL_PLANETS_ASSETS, ALL_STARS_ASSETS } from "./assets/images";

function shuffleArrayWithIndices<T>(array: T[]): { shuffledArray: T[], oldIndices: number[] } {
  const shuffledArray = [...array];
  const oldIndices = array.map((_, index) => index);

  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    [oldIndices[i], oldIndices[j]] = [oldIndices[j], oldIndices[i]];
  }

  return { shuffledArray, oldIndices };
}

let planetsTextures = [];
let indices = [];
export async function loadPlanetsTextures() {
  if (planetsTextures.length > 0) return planetsTextures;

  for (const assetToLoad of ALL_PLANETS_ASSETS) {
    planetsTextures.push(await Assets.load(assetToLoad));
  }

  const info = shuffleArrayWithIndices(planetsTextures)
  planetsTextures = info.shuffledArray;
  indices = info.oldIndices;

  // console.log({ planetsTextures, indices })

  return planetsTextures
}

export const getOldIndex = (i) => indices[i];

const starsTextures = [];
export async function loadStarsTextures() {
  if (starsTextures.length > 0) return starsTextures;

  for (const assetToLoad of ALL_STARS_ASSETS) {
    starsTextures.push(await Assets.load(assetToLoad));
  }

  return starsTextures
}