import { Container, Sprite } from "pixi.js";
import { Config, CONTAINER_SIZES } from "./settings";
import { Easing, Tween } from "@tweenjs/tween.js";
import { getOldIndex } from "./loader";

export function resize(app, planetsContainer) {
  if (planetsContainer.width > app.screen.width) {
    planetsContainer.x = app.screen.width - planetsContainer.width - CONTAINER_SIZES.padding;
  } else {
    planetsContainer.x = CONTAINER_SIZES.x;
  }
}

export function setupPlanetsContainer(app) {
  const planetsContainer = new Container();
  planetsContainer.position.set(CONTAINER_SIZES.x, CONTAINER_SIZES.y);
  app.stage.addChild(planetsContainer);
  window.addEventListener('resize', () => resize(app, planetsContainer));
  STATE.planetsContainer = planetsContainer;
  return planetsContainer;
}

const getStartPosition = (app, CONFIG) => {
  if (CONFIG.isRandomPosition) {
    return { x: Math.floor(Math.random() * app.screen.width), y: Math.floor(Math.random() * app.screen.height) }
  } else {
    return { x: app.screen.width / 2, y: app.screen.height / 2 }
  }
}

interface State {
  planetsPicked: number[],
  planetIndex: number,
  planetsContainer: Container,
  tween: Tween,
  planetsTextures: any[],
  planet: Sprite
}
// to classes
const STATE: State = {
  planetsPicked: [],
  planetIndex: undefined,
  planetsContainer: undefined,
  tween: undefined,
  planetsTextures: undefined,
  planet: undefined
}

export function setupPlanets(app, CONFIG: Config, planetsTextures) {
  STATE.planetsTextures = planetsTextures;
  STATE.planetIndex = Math.floor(Math.random() * planetsTextures.length);
  const planet = new Sprite(planetsTextures[STATE.planetIndex]);
  planet.anchor.set(0.5);
  const startPosition = getStartPosition(app, CONFIG);
  planet.position.set(startPosition.x, startPosition.y);
  planet.visible = false;
  planet.eventMode = 'static';
  planet.cursor = 'pointer';
  planet.on('pointertap', () => {
    // isPaused = !isPaused;

    // console.log("before", planetsPicked.join(), planetIndex)
    if (!STATE.planetsPicked.includes(STATE.planetIndex) || CONFIG.allowClones) {
      if (!CONFIG.isPaused || CONFIG.allowSelectOnPause) {
        const oldIndexOfSelection = getOldIndex(STATE.planetIndex);
        // const lastOldIndexFromContainer = getOldIndex(STATE.planetsContainer.children[STATE.planetsContainer.children.length - 1]?.planetIndex)
        const lastOldIndexFromContainer = getOldIndex(STATE.planetsPicked[STATE.planetsPicked.length - 1])
        // const isWithingRangePlanet = (p, n, range, total) => {
        //   let result = false;
        //   if (range <= 1) result = p + 1 === n || p === n;
        //   else result = p - range <= n && p + range >= n;
        //   console.log(p, n, range, result);
        //   return !result;
        // }
        const isWithingRangePlanet = (p, n, range, total) => {
          let result = false;
          const forwardDistance = (n - p + total) % total;
          const backwardDistance = (p - n + total) % total;
          if (range <= 1) {
            result = forwardDistance <= 1 || backwardDistance <= 1;
          } else {
            result = forwardDistance <= range || backwardDistance <= range;
          }
          // console.log(`Planet ${p}, Planet ${n}, Range ${range}, Within Range: ${result}`);
          return !result;
        };
        const isOrderBroken = CONFIG.isOrderRequired && oldIndexOfSelection !== undefined && lastOldIndexFromContainer !== undefined &&
          isWithingRangePlanet(lastOldIndexFromContainer, oldIndexOfSelection, CONFIG.nearestPlanetRange, CONFIG.totalPlanets);

        if (isOrderBroken) {
          if (!CONFIG.isRemoveAllForOrderedMode) {
            const planetIndexToRemove = STATE.planetsPicked.length - 1;
            STATE.planetsPicked.splice(planetIndexToRemove, 1);
            // console.log({ planetIndexToRemove, isOrderBroken, lastOldIndexFromContainer, oldIndexOfSelection })
            if (planetIndexToRemove !== -1) {
              STATE.planetsContainer.removeChildAt(planetIndexToRemove);
              STATE.planetsContainer.children.filter((c, i) => i >= planetIndexToRemove).forEach(c => c.position.x -= CONTAINER_SIZES.width + CONTAINER_SIZES.padding);
            }
          } else {
            STATE.planetsPicked.length = 0;
            STATE.planetsContainer.removeChildren();
            STATE.planetsContainer.x = CONTAINER_SIZES.x
          }
        }
        // if (!isOrderBroken) {
        STATE.planetsPicked.push(STATE.planetIndex);
        const planetSmall = new Sprite(planetsTextures[STATE.planetIndex % planetsTextures.length]);
        const originalSize = planetSmall.getSize();
        const aspectRatio = originalSize.height / originalSize.width;
        planetSmall.setSize(CONTAINER_SIZES.width, CONTAINER_SIZES.width * aspectRatio);
        planetSmall.position.set((STATE.planetsPicked.length - 1) * (CONTAINER_SIZES.width + CONTAINER_SIZES.padding) + CONTAINER_SIZES.margin, CONTAINER_SIZES.margin);
        planetSmall.anchor.set(0.5);
        STATE.planetsContainer.addChild(planetSmall);
        planetSmall.eventMode = 'static';
        planetSmall.cursor = 'pointer';
        planetSmall.planetIndex = STATE.planetIndex;
        planetSmall.on('pointertap', (ev) => {
          // console.log("POINTERS TAP ", STATE.planetIndex)
          // console.log({ ind: STATE.planetIndex, ind2: ev.target.planetIndex })
          let i = ev.target.planetIndex ?? STATE.planetIndex;
          CONFIG.processSmallPlanetTap && CONFIG.processSmallPlanetTap(i, CONFIG.isRandomSpeed);
        })
        // }
      }
    } else {
      if (CONFIG.allowDeselect) {
        const planetIndexToRemove = STATE.planetsPicked.findIndex(p => p === STATE.planetIndex);
        STATE.planetsPicked.splice(planetIndexToRemove, 1);
        if (planetIndexToRemove !== -1) {
          STATE.planetsContainer.removeChildAt(planetIndexToRemove);
          STATE.planetsContainer.children.filter((c, i) => i >= planetIndexToRemove).forEach(c => c.position.x -= CONTAINER_SIZES.width + CONTAINER_SIZES.padding);
        }
      }
    }

    // console.log("after", planetsPicked.join())
    if (STATE.planetsContainer.width > app.screen.width) {
      STATE.planetsContainer.x = app.screen.width - STATE.planetsContainer.width - CONTAINER_SIZES.padding;
    }
    // planet.texture = isAlien1 ? alien1texture : alien2texture;
  });

  STATE.planet = planet;
  app.stage.addChild(planet);
  return planet;
}

const scaleBig = { x: 1.0, y: 1.0 };
const scaleSmall = { x: 0.0, y: 0.0 };

export function animatePlanetOnWarpTick(app, CONFIG, warpSpeed) {
  if (warpSpeed === 0) {
    STATE.planetIndex += 1;
    STATE.planetIndex = STATE.planetIndex % STATE.planetsTextures.length;
    STATE.planet.texture = STATE.planetsTextures[STATE.planetIndex];
    const startPosition = getStartPosition(app, CONFIG);
    STATE.planet.position.set(startPosition.x, startPosition.y);
    STATE.planet.visible = true;
    const scaleK = CONFIG.isRandomSize ? Math.random() * 2 : 1;
    STATE.tween = new Tween(STATE.planet.scale)
      .to({ x: scaleBig.x * scaleK, y: scaleBig.y * scaleK }, 1000)
      .easing(Easing.Quadratic.InOut)
      .start();
  } else {
    STATE.tween = new Tween(STATE.planet.scale)
      .to(scaleSmall, 200)
      .easing(Easing.Quadratic.InOut)
      .onComplete((_) => {
        STATE.planet.visible = false;
      })
      .start();
  }
}

export function hidePlanets() {
  STATE.planet.visible = false;
  // STATE.planetsContainer.visible = false;
}

export function showPlanets() {
  STATE.planet.visible = true;
  // STATE.planetsContainer.visible = true;
}

export function processPlanetsTick(time, SETTINGS: Config) {
  STATE.planet.rotation += (SETTINGS.rotationK / 10000) * time.deltaTime;
  STATE.planetsContainer.children.forEach(c => c.rotation += (SETTINGS.rotationK / 1000) * time.deltaTime)
  /*!isPaused &&*/ STATE.tween && STATE.tween.update();
}