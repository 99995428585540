import * as Hammer from 'hammerjs';
import { Container } from "pixi.js";
import { Config, CONTAINER_SIZES } from "./settings";
// import { SCREENS_ALLOWED_PAUSE } from './screens';

const SCREENS_ALLOWED_PAUSE = [0]
export function setupGestures(app, config: Config, planetsContainer, buttonsContainer) {
  const hammer = new Hammer.Manager(app.canvas);
  hammer.add(new Hammer.Swipe());
  // hammer.add(new Hammer.Tap({ event: 'doubletap', taps: 2 }));
  // hammer.on('doubletap', (event) => {
  //   console.log('Double Tap');
  // });
  const isWithinContainer = (event: HammerInput, container: Container) =>
    event.center.x > container.position.x && event.center.x < container.position.x + container.width &&
    event.center.y > container.position.y && event.center.y < container.position.y + container.height;

  hammer.on('swipe', (event) => {
    // console.log(event.center, event.target, event.deltaX, event.distance, event.pointers)
    // console.log({ event, x: planetsContainer.position, planetsContainer, w: planetsContainer.width, hL: planetsContainer.height })

    if (event.direction === Hammer.DIRECTION_LEFT) {
      // console.log('Swipe Left');
      if (isWithinContainer(event, planetsContainer)) {
        // console.log('Within container');
        // console.log(planetsContainer.x, event.deltaX, planetsContainer.width, app.screen.width)
        if (app.screen.width < planetsContainer.width) {
          planetsContainer.x = Math.max(planetsContainer.x + event.deltaX, app.screen.width - planetsContainer.width);
          // console.log(planetsContainer.x)
        }
      }
    } else if (event.direction === Hammer.DIRECTION_RIGHT) {
      // console.log('Swipe Right');
      if (isWithinContainer(event, planetsContainer)) {
        // console.log('Within container');
        // console.log(planetsContainer.x, event.deltaX, planetsContainer.width, app.screen.width)
        planetsContainer.x = Math.min(planetsContainer.x + event.deltaX, CONTAINER_SIZES.x);
        // console.log(planetsContainer.x)
      }
    } else if (event.direction === Hammer.DIRECTION_UP) {
      // console.log('Swipe Up');
      if (SCREENS_ALLOWED_PAUSE.includes(config.screen)) {
        config.isPaused = !config.isPaused;
      }
    } else if (event.direction === Hammer.DIRECTION_DOWN) {
      // console.log('Swipe Down');
    }
  });

  return hammer;
}