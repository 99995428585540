import { Assets, Sprite } from "pixi.js";

function randomizeStar(star, initial?: any) {
  star.z = initial ? Math.random() * 2000 : STATE.cameraZ + Math.random() * 1000 + 2000;

  // Calculate star positions with radial random coordinate so no star hits the camera.
  const deg = Math.random() * Math.PI * 2;
  const distance = Math.random() * 50 + 1;

  star.x = Math.cos(deg) * distance;
  star.y = Math.sin(deg) * distance;
}

const STATE = {
  starAmount: 1000,
  cameraZ: 0,
  fov: 20,
  baseSpeed: 0.025,
  speed: 0,
  warpSpeed: 0,
  starStretch: 5,
  starBaseSize: 0.05,
  stars: []
}

export function toggleWarpSpeed() {
  STATE.warpSpeed = STATE.warpSpeed > 0 ? 0 : 1;
  return STATE.warpSpeed;
}

export async function setupStars(app) {
  // Load the star texture
  const starTexture = await Assets.load('https://pixijs.com/assets/star.png');

  // const starAmount = 1000;
  // let cameraZ = 0;
  // const fov = 20;
  // const baseSpeed = 0.025;
  // let speed = 0;
  // let warpSpeed = 0;
  // const starStretch = 5;
  // const starBaseSize = 0.05;

  // Create the stars
  // const stars = [];

  for (let i = 0; i < STATE.starAmount; i++) {
    const star = {
      sprite: new Sprite(starTexture),
      z: 0,
      x: 0,
      y: 0,
    };

    star.sprite.anchor.x = 0.5;
    star.sprite.anchor.y = 0.7;
    randomizeStar(star, true);
    app.stage.addChild(star.sprite);
    STATE.stars.push(star);
  }
}

export function processStarsTick(app, time) {
  // Simple easing. This should be changed to proper easing function when used for real.
  STATE.speed += (STATE.warpSpeed - STATE.speed) / 20;
  STATE.cameraZ += time.deltaTime * 10 * (STATE.speed + STATE.baseSpeed);
  for (let i = 0; i < STATE.starAmount; i++) {
    const star = STATE.stars[i];

    if (star.z < STATE.cameraZ) randomizeStar(star);

    // Map star 3d position to 2d with really simple projection
    const z = star.z - STATE.cameraZ;

    star.sprite.x = star.x * (STATE.fov / z) * app.renderer.screen.width + app.renderer.screen.width / 2;
    star.sprite.y = star.y * (STATE.fov / z) * app.renderer.screen.width + app.renderer.screen.height / 2;

    // Calculate star scale & rotation.
    const dxCenter = star.sprite.x - app.renderer.screen.width / 2;
    const dyCenter = star.sprite.y - app.renderer.screen.height / 2;
    const distanceCenter = Math.sqrt(dxCenter * dxCenter + dyCenter * dyCenter);
    const distanceScale = Math.max(0, (2000 - z) / 2000);

    star.sprite.scale.x = distanceScale * STATE.starBaseSize;
    // Star is looking towards center so that y axis is towards center.
    // Scale the star depending on how fast we are moving, what the stretchfactor is
    // and depending on how far away it is from the center.
    star.sprite.scale.y
      = distanceScale * STATE.starBaseSize
      + (distanceScale * STATE.speed * STATE.starStretch * distanceCenter) / app.renderer.screen.width;
    star.sprite.rotation = Math.atan2(dyCenter, dxCenter) + Math.PI / 2;
  }
}